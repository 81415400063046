import React, {  useEffect, useState } from "react";
import { Box, Typography, Switch, Grid } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { back, front, copy, timer } from "../assets";
import { Roles,ACTIONS } from "../../../../components/src/types";
import Tooltip from '@material-ui/core/Tooltip';
import PersonIcon from '@material-ui/icons/Person';
const useStyles = makeStyles(() => ({
  lessonCode: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#3F526D",
    fontWeight: 300,
  },
  mainLayout: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 20px",
    borderTop: "1px solid #F0F0F0",
  },
  code: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#2B3D7D",
    fontWeight: 600,
  },
  lessonDetails: {
    display: "flex",
    marginBottom: "5px",
  },
  switchLayout: {
    display: "flex",
    alignItems: "center",
  },
  navigationLayout: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#D4DCE5",
    borderRadius: "10px",
    width: "144px",
    justifyContent: "center",
  },
  timer: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 500,
  },
  navImage: {
    width: "28px",
    height: "28px",
    cursor: "pointer",
  },
  sildeCount: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#3F526D",
    fontWeight: 600,
    margin: "0 10px",
  },
  timerBox: {
    display: "flex",
    alignItems: "center",
  },
  copyImage: {
    width: "16px",
    height: "16px",
    marginLeft: "10px",
    cursor:"pointer"
  },
  switchLabel: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#282829",
    fontWeight: 400,
  },
  timerImage: {
    width: "12px",
    height: "14px",
    marginRight: "10px",
  },
  code_img: {
    height: "15px", width: "15px", marginLeft: "25px", cursor: 'pointer'
  }
}));
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    overflow: "unset",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);
const LiveSectionFooter = (props: any) => {
  const { studentResultList, stages, prevStage, nextStage, currentStage, room, role, handleShowResult, isOpenResult, handleStudentResult, isOpenName,lessonStartTime,isQuiz,startQuiz,setIsQuizTimedOut } = props;
  const classes = useStyles();
  const [toolTipText, setToolTipText] = useState("Copy code")
  const hideButtons = [ACTIONS.ADD_TEXT,ACTIONS.MULTIMEDIA].includes(currentStage?.attributes.lesson_stage_name as ACTIONS)
  const [lessonRunningTime, setLessonRunningTime] = useState("00:00:00");
  const [quizRunningTime, setQuizRunningTime] = useState("00:00:00");
  const [timer, setTimer] = useState<NodeJS.Timeout | any>(null);
  const [lastUpdateTime, setLastUpdateTime] = useState<number>(0);
  const [isTimerRunning, setIsTimerRunning] = useState<boolean>(false);
  useEffect(() => { 
   /*istanbul ignore next */
    if (timer) { 
      clearInterval(timer);
      setTimer(null);
    }
    /*istanbul ignore next */
    if (lessonStartTime !== '' && !isQuiz) { 
      const newTimer = setInterval(calculateTimeDifference, 1000);
      setTimer(newTimer);
      setIsTimerRunning(true);
    } else if (isQuiz && startQuiz && quizRunningTime !== "00:00:00") { 
      const newTimer = setInterval(calculateQuizTimeDifference, 1000);
      setTimer(newTimer);
      setIsTimerRunning(true);
    } else { 
      setIsTimerRunning(false);
    }
  
    return () => { 
      if (timer) {
        clearInterval(timer);
        setTimer(null);
      }
      setIsTimerRunning(false);
    };
  }, [lessonStartTime, isQuiz, startQuiz, quizRunningTime]);
  
  useEffect(() => { 
    console.log(lastUpdateTime)
  }, [isTimerRunning, lessonStartTime, isQuiz, startQuiz, quizRunningTime, currentStage]);
  
  const calculateTimeDifference = () => { 
    try {
      const receivedDate = new Date(lessonStartTime + 'Z');
      const currentDate = new Date();
      const differenceInMilliseconds = currentDate.getTime() - receivedDate.getTime();
      const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
      const hours = Math.floor(differenceInSeconds / 3600);
      const minutes = Math.floor((differenceInSeconds % 3600) / 60);
      const seconds = differenceInSeconds % 60;
  
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');
  
      setLessonRunningTime(`${formattedHours}:${formattedMinutes}:${formattedSeconds}`);
      setLastUpdateTime(Date.now());
    } catch (error) {
      console.error('Error calculating time difference:', error);
    }
  };
  /*istanbul ignore next */
  const calculateQuizTimeDifference = () => { 
    try {
      setQuizRunningTime((prevTime) => {
        const [hours, minutes, seconds] = prevTime.split(':').map(Number);
        let totalSeconds = (hours * 3600) + (minutes * 60) + (seconds - 1);
  
        if (totalSeconds <= 0) {
          if (timer) {
            clearInterval(timer);
            setTimer(null);
          }
          totalSeconds = 0;
          if (setIsQuizTimedOut) setIsQuizTimedOut(true);
        }
  
        const newHours = Math.floor(totalSeconds / 3600);
        const newMinutes = Math.floor((totalSeconds % 3600) / 60);
        const newSeconds = totalSeconds % 60;
  
        return `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}:${String(newSeconds).padStart(2, '0')}`;
      });
      setLastUpdateTime(Date.now());
    } catch (error) {
      console.error('Error calculating quiz time difference:', error);
    }
  };
  
  
  const getMainStagesLength = () => {
    return stages.filter((mainStage:any)=>/^\d+$/.test(mainStage.attributes.stage_number)).length
  }
  const getSubStageAlphabet = (curIndex: number) => {
    let result = '';
    let num = curIndex + 1;
    while (num > 0) {
        let remainder = num % 26;
        if (remainder === 0) {
            remainder = 26;
        }
        result = String.fromCharCode(64 + remainder) + result;
        num = Math.floor((num - remainder) / 26);
    }

    return result;
  };
  const getCurrentStageIndex = () => {
    const curStageNumber = currentStage.attributes.stage_number
    const mainStages = stages.filter((mainStage:any)=>/^\d+$/.test(mainStage.attributes.stage_number))
    const isMainStage = /^\d+$/.test(curStageNumber)
    if(isMainStage){
      return (mainStages.findIndex((curStage:any)=>curStage.id==currentStage.id)+1).toString()
    }
    else{
      const mainStageNumber = curStageNumber.toString().match(/^\d+/)[0];
      const allSubStages = stages.filter((curStage:any)=>
        curStage.attributes.stage_number.toString().match(/^\d+/)[0] == mainStageNumber
        && curStage.attributes.stage_number != mainStageNumber
      )
      const mainStageIndex = (mainStages.findIndex((curStage:any)=>curStage.attributes.stage_number==mainStageNumber)+1).toString()
      return mainStageIndex+getSubStageAlphabet(allSubStages.findIndex((curStage:any)=>curStage.id==currentStage.id))
    }
  }
  const handleCopyCode = (data: any) => {
    navigator.clipboard.writeText(data);
    setToolTipText("Copied")
    setTimeout(()=>{
      setToolTipText("Copy Code")
    },2000)
  };
  return (
    <Box className={classes.mainLayout}>
      <Box>
        <Box className={classes.lessonDetails}>
          <Typography className={classes.lessonCode}>
            {isQuiz?"Quiz code:":"Lesson code:"}
            {" "}
            <span className={classes.code}>{room.split("_")[1]}</span>
          </Typography>
          <Box onClick={() => handleCopyCode(room.split("_")[1])} data-test-id="copy-code">
            <Tooltip title={toolTipText} data-test-id="tooltip-text">
              <img src={copy} className={classes.copyImage} />
            </Tooltip>
          </Box>
        </Box>
        <Box className={classes.timerBox}>
          <img src={timer} className={classes.timerImage} />
          <Typography className={classes.timer} data-testId="footerTime">{isQuiz?quizRunningTime:lessonRunningTime}</Typography>
        </Box>
      </Box>
      {role === Roles.TEACHER &&  (
        <Box className={classes.switchLayout} style={{gap:"50px"}}>
          {!hideButtons && 
          <Box>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item className={classes.switchLabel}>
                Show Names
              </Grid>
              <Grid item>
                <AntSwitch
                  checked={isOpenName}
                  onChange={handleStudentResult}
                  name="showNames"
                  data-test-id="showNameSwitch"
                />
              </Grid>
            </Grid>
          </Box>
          }
          {!hideButtons && 
          <Box>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item className={classes.switchLabel}>
                Show Results
              </Grid>
              <Grid item>
                <AntSwitch
                  checked={isOpenResult}
                  onChange={handleShowResult}
                  name="showResults"
                  data-test-id="showResultSwitch"
                />
              </Grid>
            </Grid>
          </Box>}
          {!hideButtons && 
          <Box>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item className={classes.switchLabel} style={{position:"relative"}}>
                <PersonIcon style={{fontSize:"2rem"}} />
                <div style={{
                  position:"absolute",
                  top: "-10px",
                  right: "-20px",
                  fontSize: "1rem",
                  fontWeight: "bolder"
                }}>{studentResultList?.students_answered_count}/{studentResultList?.student_count}</div>
              </Grid>
            </Grid>
          </Box>}
        </Box>
      )}
      <Box className={classes.navigationLayout}>
        <img
          data-test-id="prev-btn"
          src={back}
          className={classes.navImage}
          onClick={() => prevStage()}
        />
        <Typography className={classes.sildeCount}>
          {getCurrentStageIndex()}/{getMainStagesLength()}
        </Typography>
        <img
          data-test-id="next-btn"
          src={front}
          className={classes.navImage}
          onClick={() => nextStage()}
        />
      </Box>
    </Box>
  );
};

export default LiveSectionFooter;
