import React, { useEffect, useState, useRef } from "react";
import LiveSectionHeader from "../../../LiveStreaming/src/components/LiveSectionHeader.web";
import LiveSectionFooter from "../../../LiveStreaming/src/components/LiveSectionFooter.web";
import { Box, Grid, Typography, Modal} from "@material-ui/core";
import StudentChatSectionHeader from "./components/StudentChatHeader.web";
import StudentComments from "./components/StudentComments.web";
import Loader from "../../../../components/src/Loader.web";
import StudentLiveScreen from "./components/StudentLiveScreen.web";
import StudentMcqLive from "./components/StudentMcqLive.web";
import StudentOpenEndedLive from "./components/StudentOpenEndedLive.web";
import StudentMatchingLive from "./components/StudentMacthingLive.web";
import { makeStyles } from "@material-ui/core/styles";
import { CHAT ,ACTIONS,QuestionHeaders} from "../../../../components/src/types";
import { getStorageData} from "../../../../framework/src/Utilities";
import SubStageResultsModal from "../../../../components/src/SubStageResultsModal.web";
import LinearProgress from '@material-ui/core/LinearProgress';
import WhiteboardCollaboration from "../../../whiteboardcollaboration/src/WhiteboardCollaboration";
import StudentWordCloud from "./components/StudentWordCloud.web";
import {closeIcon} from "../assets"
import QuizModal from "../../../../components/src/QuizModal.web";
import firebase from "firebase";
const useStyles = makeStyles(() => ({
  liveScreen: {
    height: "82vh",
    backgroundColor: "#F0F0F0",
    overflow:"auto"
  },
  modalContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  modalStyle:{
    position: "absolute",
    width: "420px",
    borderRadius: "10px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    padding:"50px 20px",
    minHeight: "160px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline:'0 !important',
    border:'0 !important',
    "& .close-modal":{
      display:"block",
      position: "absolute",
      top: "0",
      right: "0",
    },
    "& .cross-icon":{
      cursor:"pointer"
    },
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    backgroundColor:"#fff"
  } , 
  popupContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom:"0",
  } ,
  heading: {
    fontFamily: "Poppins",
    fontSize: "18px",
    color: "#2C2C2E",
    fontWeight: 600,
    lineHeight:"1.5",
    textAlign:"center",
    padding:"0 15px",
    marginBottom:"20px",
  },
  headingBold:{
    color:"#2B3D7D",
    fontSize:"36px",
    fontWeight:600,
    lineHeight:1.3,
    textAlign:"center",
  }
}));
const StudentLiveStreaming = (props: any) => {
  const classes = useStyles();
  let config = require("../../../../framework/src/config")
  const {
    messages,
    currentStage,
    stages,
    room,
    studentProfileImage,
    studentName,
    getStudentDetails,
    showQuestion,
    lessonName,
    className,
    parentStage,
    addChartSubmitData,
    success,
    setSuccess,
    hideSuccessMessage,
    leaveLesson,
    studentId,
    pollingResult,
    screen,
    setScreen,
    studentAnswer,
    wordCloudAnswer,
    stageAnswers,
    lessonStudentStartTime,
    isQuizStudent,
    isNewStage,
    redirectToPage,
    isPlaying,
    seconds,
    nextStage,
    prevStage,
    scoreData,
    createQuizStudentLibrary,
    isGuest,
    highlightJson,
    highlightJsonDesc,
    showLoader
  } = props;
  let { showResult } = props
  const [chatOption, setChatOption] = useState<string>(CHAT.QUESTION);
  const [selectedOption, setSelectedOption] = useState<any>();
  const [selectedOptionId, setSelectedOptionId] = useState<number|number[]>(0);
  const [token, setToken] = useState("");
  const [fullscreen, setFullScreen] = useState<boolean>(false);
  const [quizTimer,setQuizTimer] = useState<number>(3);
  const [showNextModal,setShowNextModal] = useState(false);
  const [showScoreModal,setshowScoreModal] = useState(false);
  const [startQuizQuestion,setStartQuizQuestion] = useState(false);
  const [isFinalScore,setIsFinalScore] = useState(false);
  let quizInterval = useRef<NodeJS.Timeout|null>(null);
  const stageType = isQuizStudent?currentStage?.attributes.quiz_stage_name as ACTIONS:currentStage?.attributes.lesson_stage_name as ACTIONS
  const QuestionStages = [
    ACTIONS.MCQ,
    ACTIONS.ADD_WORD_CLOUD,
    ACTIONS.KWL_CHART,
    ACTIONS.WH_CHART,
    ACTIONS.SEED_DISCUSSION,
    ACTIONS.T_CHART,
    ACTIONS.VENN_DIAGRAM,
    ACTIONS.OPEN_ENDED_QUESTION,
    ACTIONS.ADD_MATCHING_Q,
    ACTIONS.POLLING_Q];
  const [isQuizTimedOut,setIsQuizTimedOut] = useState(false)
  const quizResult = showResult
  if(isQuizStudent && showResult && stageType!=ACTIONS.POLLING_Q){
    showResult = false

  }
  useEffect(() => {
    callToken();
  }, []);

  useEffect(()=>{
    if(!isFinalScore){
      const showModal = quizResult && [ACTIONS.MCQ,ACTIONS.ADD_MCQ,ACTIONS.ADD_MATCHING_Q].includes(stageType)
      setshowScoreModal(showModal)
    }
  },[quizResult])
  
  const setAnswersForMatchinOpen = () => {
    const currentStageAnswer = stageAnswers.length>0?stageAnswers[0]:null;
    if([ACTIONS.OPEN_ENDED_QUESTION,ACTIONS.ADD_MATCHING_Q].includes(stageType)){
      if(isQuizStudent){
        setSuccess(false)
      }
      else{
        if(currentStageAnswer.answers && currentStageAnswer.answers.length>0){
          setSuccess(false)
        }
      }
    }
  }

  const setAnswersForOtherStages = () => {
    const currentStageAnswer = stageAnswers.length>0?stageAnswers[0]:null;
    if(stageType == ACTIONS.POLLING_Q){
        let currentAnswerPoll = isQuizStudent?currentStageAnswer.student_answer: currentStageAnswer.correct_answer
        const isMultiple = currentStage.attributes.multiple??false;
        if(isMultiple){
          currentAnswerPoll = JSON.parse(currentAnswerPoll)
        }
        const optionPoll = currentStage.attributes.questions[0]?.options.filter((x:any)=>currentAnswerPoll.includes(x.answer))
        
        if(optionPoll.length>0){
          setSelectedOption(currentAnswerPoll)
          setSelectedOptionId(optionPoll.map((option:any)=>option.id))
          setSuccess(false)
        }
    }
    else if([ACTIONS.KWL_CHART,ACTIONS.T_CHART,ACTIONS.WH_CHART,ACTIONS.VENN_DIAGRAM,ACTIONS.SEED_DISCUSSION].includes(stageType)){
      for (let index = 0; index < currentStage?.attributes.description.length; index++) {
        currentStage.attributes.description[index].answer = index<currentStageAnswer.answers.length?currentStageAnswer.answers[index].answer:""
      }
      setSuccess(false)
    }  
  }

  const setOptionDefaultValue = () => {
    const newStageType = isQuizStudent?currentStage?.attributes.quiz_stage_name as ACTIONS:currentStage?.attributes.lesson_stage_name as ACTIONS
      if(newStageType == ACTIONS.POLLING_Q || newStageType == ACTIONS.ADD_POLL){
        setSelectedOption([])
        setSelectedOptionId([])
      }
      else{
        setSelectedOption(undefined)
          setSelectedOptionId(0)
          
      }    
  }
  useEffect(()=>{
    const currentStageAnswer = stageAnswers.length>0?stageAnswers[0]:null;
    
    if(!currentStageAnswer || !stageType) {
      setOptionDefaultValue()
      return  
    }
    switch(stageType){
      case ACTIONS.MCQ:
        const currentAnswer = isQuizStudent?currentStageAnswer.student_answer: currentStageAnswer.answers[0].answer
      
        const option = currentStage.attributes.questions[0]?.options.find((x:any)=>x.answer ==currentAnswer)
        
        if(option){
          setSelectedOption(currentAnswer)
          setSelectedOptionId(option.id)
          setSuccess(false)
        }
        break;
      case ACTIONS.ADD_WORD_CLOUD:
        for (let index = 0; index < currentStage?.attributes.description.length; index++) {
          currentStage.attributes.description[index].answer = index<currentStageAnswer.answers.length?currentStageAnswer.answers[index].answer:""
        }
        setSuccess(false)
        break;
     
      default:
        setAnswersForMatchinOpen();
        setAnswersForOtherStages();
    } 
  },[stageAnswers])

  const callToken = async () => {
    let tokenVal = await getStorageData("authToken");
    setToken(tokenVal);
  }

  const renderSubStageQuestion = () => {
    if ((!currentStage || parentStage.id == currentStage.id || success)) return;
    if (stageType=== ACTIONS.MCQ) {
      return <StudentMcqLive stage={currentStage} isSubStage={true}
      handleSubmit={handleSubmit} selectedOption={selectedOption} setSelectedOption={setSelectedOption} showQuestion={showQuestion} showResult={showResult} selectedOptionId={selectedOptionId} setSelectedOptionId={setSelectedOptionId} isGuest={isGuest}/>;
    } else if (
      stageType === ACTIONS.OPEN_ENDED_QUESTION
    ) {
      return <StudentOpenEndedLive stage={currentStage}  showQuestion={showQuestion} isSubStage={true} setSuccess={setSuccess} showResult={showResult} isQuizStudent={isQuizStudent} isGuest={isGuest}/>;
    }
  };

  const renderAnswers = () => {
    const isSubStage = parentStage.id != currentStage.id
    if (stageType === ACTIONS.MCQ) {
      return <StudentMcqLive selectedOption={selectedOption} stage={currentStage} showAnswers={true} showResult={true} isSubStage={isSubStage} selectedOptionId={selectedOptionId} isGuest={isGuest}/>;
    } else if (
      stageType === ACTIONS.ADD_MATCHING_Q
    ) {
      return <StudentMatchingLive stage={currentStage} showAnswers={true}/>;
    } else if (
      stageType === ACTIONS.POLLING_Q
    ) {
      return <StudentMcqLive selectedOption={selectedOption} stage={currentStage} showAnswers={true} showResult={true} isSubStage={false} selectedOptionId={selectedOptionId} isPolling={true} pollingResult={pollingResult}/>;
    }
    else if (
      stageType === ACTIONS.ADD_WORD_CLOUD
    ) {
      return <StudentWordCloud stage={currentStage} showAnswers={true} studentAnswer={studentAnswer} />;
    }
    
    else if (
      stageType === ACTIONS.OPEN_ENDED_QUESTION && isSubStage
    ) {
      return <StudentOpenEndedLive stage={currentStage}  showQuestion={showQuestion} isSubStage={true} setSuccess={setSuccess} showResult={true} stageAnswers={stageAnswers} isGuest={isGuest}/>
    }
  };
  const renderChatContent = () => {
    if (chatOption === CHAT.QUESTION) {
      return showResult?renderAnswers():renderSubStageQuestion()
    } else {
      return (
        <StudentComments
          messages={messages}
          room={room}
          studentName={studentName}
          studentProfileImage={studentProfileImage}
          studentId={studentId}
          sendChatMessage={props.sendChatMessage}
          isGuest={isGuest}
        />
      );
    }
  };
  const handleChatHeaderOption = (option: string) => {
    setChatOption(option);
  };
  
  const handleSubmit = async (isPolling:boolean) => {
    if(!selectedOption || selectedOption.length==0) return
    let baseURL = `${config.baseURL}/bx_block_livestreaming/live_lesson/submit_lesson_answer`
    let question = currentStage?.attributes?.questions[0].question.question
    let body = isPolling ? {
      lesson_id: currentStage.attributes.lessons_id,
      lesson_markup_tools_id: currentStage.id,
      correct_answer: selectedOption, 
      question: question,
      quiz_id: currentStage.attributes.quiz_id,
      quiz_markup_tools_id: currentStage.id,
      answers:
        selectedOption.map((selectedAnswer:string)=>(
          {
            answer:selectedAnswer
          }
        ))
  }:{
    lesson_id: currentStage.attributes.lessons_id,
    lesson_markup_tools_id: currentStage.id,
    answers: [
      {
        question: question,
        answer: selectedOption[0]
      }
    ],
    quiz_id: currentStage.attributes.quiz_id,
    quiz_markup_tools_id: currentStage.id,
  }
    if(isQuizStudent){
      baseURL = `${config.baseURL}/bx_block_content_management/quizs/submit_quiz_answer`

      body = isPolling ? {
        ...body,
        lesson_id: currentStage.attributes.lessons_id,
        lesson_markup_tools_id: currentStage.id,
        quiz_id: currentStage.attributes.quiz_id,
        quiz_markup_tools_id: currentStage.id,
        question: currentStage?.attributes?.questions[0].question.question
    }:{
      lesson_id: currentStage.attributes.lessons_id,
      lesson_markup_tools_id: currentStage.id,
      answers: [
        {
          question: currentStage?.attributes?.questions[0].question.question,
          answer: selectedOption[0]
        }
      ],
      quiz_id: currentStage.attributes.quiz_id,
      quiz_markup_tools_id: currentStage.id,
    }
    }
    const db = firebase.firestore();
    db
    .collection('users')
    .where("id", "==", studentId)
    .where("room", "==", room)
    .get().then(querySnapshot => {
      querySnapshot.docs.forEach(snapshot => {
          snapshot.ref.update({
            studentId: studentId,
            studentAnswer: body.answers
          });
      })
    })
   
    let response = await fetch(baseURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token
      },
      redirect: "follow",
      body: JSON.stringify(body)
    });
    if (response.ok) {
      const resp = await response.json()

      if(resp.error == "Request size exceeds the maximum allowed size of 200 MB"){
        setSuccess(false,true);
      }
      else{
        setSuccess();
      }
    }
  };
useEffect(()=>{
  hideSuccessMessage()
  setIsQuizTimedOut(false)
},[currentStage])


const isQuestionStage=()=>{
  let isQuestion = QuestionStages.includes(stageType ?? "");
  return isQuestion
}

const getQuestionType=()=> {
  switch(stageType?? ""){
    case ACTIONS.MCQ: return QuestionHeaders.MCQ;
    case ACTIONS.ADD_WORD_CLOUD: return QuestionHeaders.ADD_WORD_CLOUD
    case ACTIONS.KWL_CHART: return QuestionHeaders.KWL_CHART
    case ACTIONS.WH_CHART: return QuestionHeaders.WH_CHART
    case ACTIONS.SEED_DISCUSSION: return QuestionHeaders.SEED_DISCUSSION
    case ACTIONS.T_CHART: return QuestionHeaders.T_CHART
    case ACTIONS.VENN_DIAGRAM: return QuestionHeaders.VENN_DIAGRAM
    case ACTIONS.OPEN_ENDED_QUESTION: return QuestionHeaders.OPEN_ENDED_QUESTION
    case ACTIONS.ADD_MATCHING_Q: return QuestionHeaders.ADD_MATCHING_Q
    case ACTIONS.POLLING_Q: return QuestionHeaders.POLLING_Q
    default : return ""
  }
}

const modifyFullScreen = () => {
  setFullScreen(!fullscreen)
}


const clearQuizInterval = () => {
  if (quizInterval.current) clearInterval(quizInterval.current);
}

useEffect(()=>{
  if(currentStage == null) return
  setQuizTimer(3)
  if(showQuestion) setShowNextModal(true)
  else setStartQuizQuestion(false)
},[showQuestion])

useEffect(()=>{
  if(currentStage == null || !isNewStage || showQuestion) return
  setShowNextModal(false)
  setStartQuizQuestion(false)
},[currentStage])

useEffect(()=>{
  if(!showNextModal) return
  quizInterval.current = setInterval(quizCountdown,1000)
  return () => {
    clearQuizInterval()
  };
},[showNextModal])
const quizCountdown = () => {
  setQuizTimer((prevState) => {
    if (prevState === 1) {
      clearQuizInterval()
      setShowNextModal(false)
      setStartQuizQuestion(true)
      return 0;
    }
    return prevState - 1;
  });
};

const closeNextModal = () => {
  clearQuizInterval()
  setShowNextModal(false)
  setStartQuizQuestion(true)
}

const quizQuestionLoadingModal = () => {
  return (
  <Modal
      open={showNextModal}
  >
    <Box className={classes.modalStyle}>
      <a data-test-id="closeLeaveLessonModal" title="close modal" className="close-modal">
        <img src={closeIcon} alt="Modal close icon" className="cross-icon" data-testId="closeQuizModal" onClick={closeNextModal}/>
      </a>
      <Box className={classes.popupContent}>        
        <Typography className={classes.heading}>
          Get ready for the next question
        </Typography>
        <Typography className={classes.headingBold}>
        {`00:00:0${quizTimer} sec`}
        </Typography>
      </Box>
    </Box>
  </Modal>
  )
}

const handleCloseScore = () => {
  setshowScoreModal(false)
  isFinalScore && createQuizStudentLibrary()
}

  return (
    <Box>
      <Grid container style={{ overflowX: "hidden", height: "100%" }}>
        <Grid item xs={fullscreen?12:9} style={{ borderRight: "1px solid #F0F0F0" }}>
          {(!currentStage || showLoader) ? (
            <Loader loading={true} />
          ) : (
            <>
              <Grid item md={12}>
                <LiveSectionHeader lessonName={lessonName} className={className} leaveLesson={isQuizStudent?()=>{setIsFinalScore(true);setshowScoreModal(true)}:leaveLesson} screen={screen} setScreen={setScreen} modifyFullScreen={modifyFullScreen} fullscreen={fullscreen} isQuiz={isQuizStudent}/>
              </Grid>
              <Grid item md={12} className={classes.liveScreen}>
                <SubStageResultsModal 
                  open={currentStage && parentStage.id != currentStage.id && showResult && stageType === ACTIONS.MCQ && selectedOption} 
                >
                  <StudentMcqLive selectedOption={selectedOption} stage={currentStage} showAnswers={true} showResult={true} isSubStage={false} isPopup={true}/>
                </SubStageResultsModal>
                {
                  screen=="Lesson"?
                  <StudentLiveScreen wordCloudAnswer={wordCloudAnswer} currentStage={parentStage} showQuestion={showQuestion} showResult={showResult} handleSubmit={handleSubmit} selectedOption={selectedOption} setSelectedOption={setSelectedOption} setSuccess={setSuccess} addChartSubmitData={addChartSubmitData} hideSuccessMessage={hideSuccessMessage} selectedOptionId={selectedOptionId} setSelectedOptionId={setSelectedOptionId} stageAnswers={stageAnswers} isQuizStudent={isQuizStudent} isQuizTimedOut={isQuizTimedOut} seconds={seconds} isPlaying={isPlaying} isGuest={isGuest} highlightJson={highlightJson} highlightJsonDesc={highlightJsonDesc}/>
                  :<WhiteboardCollaboration navigation={() => { }} id="" fullscreen={fullscreen} />
                }
              </Grid>
              <Grid item md={12}>
                <LiveSectionFooter
                  data-test-id="live-footer"
                  stages={stages}
                  currentStage={currentStage}
                  nextStage={nextStage}
                  prevStage={prevStage}
                  room={room}
                  lessonStartTime={lessonStudentStartTime}
                  isQuiz={isQuizStudent}
                  startQuiz={startQuizQuestion}
                  setIsQuizTimedOut={setIsQuizTimedOut}
                />
              </Grid>
              {isQuizStudent && 
               <QuizModal stage={currentStage} open={showScoreModal} handleClose={handleCloseScore} isFinalScore={isFinalScore} name={lessonName} scoreData={scoreData}/>
              }
            </>
          )}
        </Grid>

        {!fullscreen && <Grid item xs={3} style={{ overflowY: "scroll", height: "100%" }}>
          <Grid item md={12}>
            <StudentChatSectionHeader
              handleChatHeaderOption={handleChatHeaderOption} success={success} isSubStage={currentStage && parentStage.id != currentStage.id} isQuestionStage={isQuestionStage()} questionType={getQuestionType()} showResult={showResult} isGuest={isGuest}
            />
          </Grid>
          <Grid item md={12} style={{position:"relative"}}>
            {renderChatContent()}
          </Grid>
        </Grid>}
        {isQuizStudent && quizQuestionLoadingModal()}
      </Grid>
    </Box>
  );
};

export default StudentLiveStreaming;
