Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.dashboardGetUrl = "bx_block_dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.getTeacherClasses = "bx_block_content_management/teacher_flows/teacher_class_index";
exports.getAssignments = "bx_block_content_management/assignments/list_of_assignments";
exports.getQuizzes = "bx_block_content_management/teacher_flows/list_of_quizzes";
exports.getLessons = "bx_block_content_management/teacher_flows/teacher_lesson_index";
exports.deleteLesson = "bx_block_content_management/teacher_flows/delete_lesson";
exports.deleteAssignment = "bx_block_content_management/assignments/delete_assignment";
exports.deleteQuiz = "bx_block_content_management/teacher_flows/delete_quiz";
exports.DELETE = "DELETE";
exports.deleteConfirmation = "Are you sure you want to delete this";
exports.shareLinkDescription = "Share the link below with your students to send them directly to your assignment.";
exports.getCurrentUserClasses = "bx_block_content_management/teacher_flows/current_user_classes_list"
exports.getTeacherClassLesson = "bx_block_content_management/teacher_flows/teacher_lesson_index"
exports.getNotesApiEndPoint = "bx_block_notes/notes";
exports.POST = "POST";
exports.createLessonFolder = "bx_block_content_management/lessons/create_lesson_folder";
exports.getFolderList = "bx_block_content_management/lessons/current_user_lesson_folder_list";
exports.getClassesDataApi = "bx_block_content_management/teacher_flows/current_user_classes_list"
exports.PUT = "PUT";
exports.moveLessonToFolder = "bx_block_content_management/lessons/move_lesson_folder";
exports.getLessonFolderList = "bx_block_content_management/lessons/lesson_folder_list"
exports.deleteLessonFolder = "bx_block_content_management/lessons/delete_folder_or_lesson";
exports.deleteStudentLesson = "bx_block_content_management/lessons/delete_lesson_library_data";
exports.deleteStudentQuiz = "bx_block_content_management/quizs/delete_quiz_library_data"
exports.getLessonListByFolder = "bx_block_content_management/lessons/show_folder";
exports.moveOutLesson = "bx_block_content_management/lessons/move_out_lesson";
exports.getStudentClasslist="bx_block_peoplemanagement2/people_managements/student_classes_list"
exports.getStagesApi="bx_block_cfcoursecreation23/lesson_markups/lesson_markup_tools"
exports.WebAPIKey=  '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'
exports.WebClienctID = '580372478236-u49s5ehcgpqih5eptqi79kuur5ma9ouf.apps.googleusercontent.com'
exports.webClientId = '580372478236-u49s5ehcgpqih5eptqi79kuur5ma9ouf.apps.googleusercontent.com'
exports.driveScope = 'https://www.googleapis.com/auth/drive'
exports.getSharedListing="bx_block_content_management/shared_libraries/index_shared_resources"
exports.getLessonsForStudent="bx_block_content_management/lessons/student_lesson_library_list";
exports.getQuizzesForStudent="bx_block_content_management/quizs/student_quiz_library_list";
exports.getAssignmentsForStudent="bx_block_content_management/assignments/student_assignment_library_list"
exports.addHighlightToText="bx_block_annotations/annotations/highlight_text"
exports.getQuizFolderList="bx_block_content_management/teacher_flows/quiz_folder_list"
exports.createQuizFolder="bx_block_content_management/teacher_flows/create_quiz_folder"
exports.getAssignmentFolderListing="bx_block_content_management/assignments/assignment_folder_list"
exports.createAssignmentFolder="bx_block_content_management/assignments/create_assignment_folder"
exports.deliverLesson = "bx_block_cfcoursecreation23/lesson_markups/update_live_lesson"
exports.showLesson = "bx_block_content_management/teacher_flows/show_lesson"
exports.updateLesson="bx_block_content_management/teacher_flows/update_lesson"
exports.PATCH = "PATCH";
exports.downloadLesson = "bx_block_download/download/download_lesson";
exports.getFoldersforQuizzes="bx_block_content_management/teacher_flows/current_user_quiz_folder_list"
exports.moveQuizToFolder="bx_block_content_management/teacher_flows/move_quiz_folder"
exports.getFoldersforAssignments="bx_block_content_management/assignments/current_user_assignment_folder_list";
exports.moveAssignmentToFolder="bx_block_content_management/assignments/move_assignment_folder";
exports.showAssignmentFolder="bx_block_content_management/assignments/show_assignment_folder"
exports.showQuizFolder="bx_block_content_management/teacher_flows/show_quiz_folder"
exports.moveOutAssignment="bx_block_content_management/assignments/move_out_assignment"
exports.moveOutQuiz="bx_block_content_management/teacher_flows/move_out_quiz_folder"
exports.deleteQuiz="bx_block_content_management/teacher_flows/delete_quiz";
exports.deleteQuizFolder="bx_block_content_management/teacher_flows/delete_folder?quiz_folder_id=";
exports.deleteAssignment="bx_block_content_management/assignments/delete_assignment"
exports.updateQuiz="bx_block_content_management/teacher_flows/update_quiz";
exports.updateAssignment="bx_block_content_management/assignments";
exports.showAssignment="bx_block_content_management/assignments";
exports.showQuiz="bx_block_content_management/teacher_flows/show_quiz";
exports.getAssignmentStages="bx_block_assessmenttest/assignment_markups/assignment_markup_tools"
exports.getQuizStages="bx_block_questionbank/quiz_markups/quiz_markup_tools"
exports.deleteFolderForQuiz="bx_block_content_management/teacher_flows/delete_folder";
exports.deleteAssignmentFolder="bx_block_content_management/assignments/delete_assignment_folder";
exports.deliverQuiz="bx_block_questionbank/quiz_markups/update_live_quiz";
exports.downloadendpoint="bx_block_download/download/download_to_cloud";
exports.updateQuizFolder="bx_block_content_management/teacher_flows/update_quiz_folder";
exports.updateAssignmentFolder="bx_block_content_management/assignments/update_assignment_folder";
exports.updateLessonFolder="bx_block_content_management/lessons/update_folder"
exports.getHighlightsForLesson="bx_block_annotations/annotations/highlight_texts"
exports.getHighlightsForQuiz="bx_block_annotations/annotations/quiz_highlight_texts";
exports.getHighlightsForAssignments="bx_block_annotations/annotations/assignment_highlight_texts";
exports.getCommentData="bx_block_comments/add_comment";
exports.getMethod="GET"
exports.viewSubFolder="View Sub-Folder"
exports.showLessonSubFolder="bx_block_content_management/lessons/show_folder";
exports.showQuizSubFolder="bx_block_content_management/teacher_flows/show_quiz_folder";
exports.showAssignmentSubFolder="bx_block_content_management/assignments/show_assignment_folder";
exports.uploadLessonEndPoint = "bx_block_content_management/lessons/upload_lesson_json";
exports.uploadQuizEndPoint = "bx_block_content_management/quizs/upload_quiz_file";

// Customizable Area End