import React, {  useEffect, useState } from "react";
import { Box, Typography, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { backImage ,ImageSearchIcon,TickRight,Cross} from "../../assets";
import GenericButton from "../../../../../components/src/GenericButton.web";
import AddText from "./AddText.web";
import Chart from "./Chart.web";
import VennDiagram from "./VennDiagram.web";
import ChartAddedSuccessModal from "./ChartAddedSuccessModal.web";
import { ACTIONS, COURSES_PAGES } from "../../../../../components/src/types";
import MultiMedia from "./MultiMedia.web";
import ContentEditable from "react-contenteditable";
import Mcq from "./Mcq.web";
import NewStage from "./NewStage.web";
import CFWordCloud from "../../../../CFWordCloud/src/CFWordCloud.web";
import McqModal from "./McqModal.web";
import McqAddedSuccessModal from "./McqAddedSuccessModal.web";
import Poll from "./Poll.web";
import OpenEndedQuestion from "./OpenEndedQuestion.web";
import CfQuestionBank2 from "../../../../CfQuestionBank2/src/CfQuestionBank2.web";
import AddMatchingQModal from "./AddMatchingQModal.web";
import DrawingCanvas from "../HeaderTools.web";
import Loader from "../../../../../components/src/Loader.web";
import TextEditor from "../../../../../components/src/TextEditor";

const useStyles = makeStyles(() => ({
  title: {
    textAlign: "center",
    fontSize: "18px",
    color: "#2C2C2E",
    fontFamily: "Poppins",
    fontWeight: 400,
    marginLeft: "10px",
    marginTop:"20px",
    "&.arrow-title":{
      marginTop:"0"
    }
  },
  nav: {
    display: "flex",
    marginTop: "30px",
    marginBottom: "30px",
  },
  contentTitleFull: {
    width:"80%",
    fontSize:"18px",
    textAlign:"left",
    fontFamily: "Poppins",
    marginBottom:"8px",
    "&:empty:before": {
      content: "attr(data-placeholder)",
      color: "#9FA1A4",
      fontSize: "20px",
      fontWeight: 600,
      fontFamily: "Poppins",
    },
    "&:focus-visible": {
      outline: "none",
    },
  },
  contentTitle: {
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Poppins",
    paddingRight:"150px",
      "&:empty:before": {
      content: "attr(data-placeholder)",
      color: "#9FA1A4",
      fontSize: "20px",
      fontWeight: 600,
      fontFamily: "Poppins",
    },
    "&:focus-visible": {
      outline: "none",
    },
  },
  contentTitlepadding:{
    paddingRight:"150px",
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Poppins",
    "&:focus-visible": {
      outline: "none",
    },
  },
  contentTitleMCQ:{
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Poppins",
      "&:empty:before": {
      content: "attr(data-placeholder-mcq)",
      color: "#9FA1A4",
      fontSize: "20px",
      fontWeight: 600,
      fontFamily: "Poppins",
    },
    "&:focus-visible": {
      outline: "none",
    },
  },
  contentTitlePOLL:{
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Poppins",
      "&:empty:before": {
      content: "attr(data-placeholder)",
      color: "#9FA1A4",
      fontSize: "20px",
      fontWeight: 600,
      fontFamily: "Poppins",
    },
    "&:focus-visible": {
      outline: "none",
    },
  },
  contentTitleOPENENDED:{
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Poppins",
      "&:empty:before": {
      content: "attr(data-placeholder)",
      color: "#9FA1A4",
      fontSize: "20px",
      fontWeight: 600,
      fontFamily: "Poppins",
    },
    "&:focus-visible": {
      outline: "none",
    },
  },
  contentWithoutBtnTitle:{
  fontSize: "20px",
  fontWeight: 600,
  fontFamily: "Poppins",
    "&:empty:before": {
    content: "attr(data-placeholder)",
    color: "#9FA1A4",
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Poppins",
  },
  "&:focus-visible": {
    outline: "none",
  },
},
  contentTitleMcq: {
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: "Poppins",
      "&:empty:before": {
      content: "attr(data-placeholder-mcq)",
      color: "#9FA1A4",
      fontSize: "20px",
      fontWeight: 600,
      fontFamily: "Poppins",
    },
    "&:focus-visible": {
      outline: "none",
    },
  },
  modalStyles: {
    width: "475px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    overflow: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#fff",
    border: "none",
    overflowX: "hidden",
    boxShadow: "24",
    borderRadius: "15px",
    padding: "24px",
    height:"268px"
  },
  contentTitleBox: {
    minHeight: "95px",
    border: "1px dashed rgba(149, 157, 165)",
    borderRadius: "10px",
    marginBottom: "20px",
    padding:"15px 20px",
    position:"relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow:"auto",
    whiteSpace:"pre-wrap",
    wordWrap:"break-word",
    flexDirection:"column",
  },
  contentTitleBox2: {
    minHeight: "95px",
    borderRadius: "10px",
    marginBottom: "20px",
    position:"relative",
    display: "flex",
    justifyContent: "center",
    whiteSpace:"pre-wrap",
    wordWrap:"break-word",
    flexDirection:"column",
  },
  contentTitleBoxFull: {
    minHeight: "95px",
    border: "1px dashed rgba(149, 157, 165)",
    borderRadius: "10px",
    marginBottom: "20px",
    position:"relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    overflowY:"auto",
    whiteSpace:"pre-wrap",
    wordWrap:"break-word",
    padding:"8px 20px",
    flexDirection:"column",
  },
  editArea: {
    height: "612px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    backgroundColor: "#F0F0F0",
    marginBottom: "50px",
  },
  btnLayout: {
    display: "flex",
    justifyContent: "end",
    marginBottom: "50px",
  },
  previewLabel: {
    fontFamily: 'Poppins',
    fontSize: "18px",
    fontWeight: 400,
    color: "#2B3D7D",
  },
  insertImageWrapper:{
    display: "none",
    alignItems: "center",
    gap:"8px",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "4px",
    padding:"4px 15px",
    position: "absolute",
    top: "8px",
    right: "18px",
    cursor:"pointer",
    marginLeft:"15px"
  },
  previewWrapper: {
    display: "flex",
    alignItems: "start",
    overflowX: "auto",
    flexWrap: "nowrap",
    gap: "10px",
    marginTop: "10px"
  },
  previewBox: {
    backgroundColor: "#fff",
    width: "30px",
    height:"30px",
    flex: "0 0 auto",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  previewTitile: {
    padding: "0 12px",
  },
  previewImg: {
    width: "80px",
    height: "80px",
    margin: "0 auto"
  },
  crossImgStyle:{
    height: "50px",
    width: "50px", 
    position: "absolute", 
    right: "10px", 
    top: "10px",
    cursor:"pointer"
  },
  tickImg:{
    height:"60px",
    width:"60px", 
    marginTop: "33px"
  },
  stageBox:{
    color:"#2c3e7e",
    fontWeight:"bold",
    fontSize:"20px",
    marginTop:"20px",
    fontFamily: "Poppins",
  },
  successBox:{
    color:"#2c3e7e",
    fontWeight:"bold",
    fontSize:"20px",
    fontFamily: "Poppins",
  },
  suavedTextStyle:{
    justifContent: "center",
    display: "contents",
    flexDirection:"column"
  }
  
}));

interface TimeSet {
  minutes: string;
  seconds: string;
}

const EditLayout = (props: any) => {
  const {
    redirectToDashboard,
    action,
    addTextStage,
    multiMediaData,
    addChartStage,
    lessonName,
    setTitle,
    isNew,
    title,
    setAddTextState,
    addTextState,
    chartState,
    setChartState,
    multiMediaState,
    vennState,
    canvas,
    status,
    setVennState,
    editMultiMediaId,
    updateMultiMediaStage,
    addWordCloudState,
    setAddWordCloudState,
    addWordCloudStage,
    enteriesCount,
    setEnteriesCount,
    preview, 
    setPreview,
    addMcqStage,
    addPollStage,
    mcqState,
    pollState,
    openendedState,
    setopenendedState,
    addOpenEndedTextState,
    setAddOpenEndedTextState,
    addOpenEndedStage,
    matchquestionState,
    setmatchquestionState,
    setMcqfile,
    matchSettingState,
    setMatchSettingState,
    mcqfile,
    setPollfile,
    pollfile,
    setOpenEndedQuestionfile,
    openEndedQuestionfile,
    selectedView,
    pairs,
    setPairs,
    pairCount,
    setPairCount,
    addMatchingQStage,
    handleMcqClick,
    loading,
    parentStage,
    stageId,
    createdAction,
    handleActionChange,
    handleOpenEndedChange,
    openEndedState,
    handleMcqChange,
    handleMatchChange,
    handlePollChange,
    matchSetState,
    pollSettingState,
    mcqSettingState,
    openCanvas,
    setOpenCanvas,
    hasCanvasItem,
    setHasCanvasItem,
    setIsSaveDisabled,
    isSaveDisabled
  } = props;
  const classes = useStyles();
  const [successModal, setSuccessModal] = React.useState<boolean>(false);
  const [successChartModal, setSuccessChartModal] = React.useState<boolean>(false);
  const [modalMcqOpen, setModalMcqOpen] = React.useState<any>(false);
  const [modalPollOpen, setModalPollOpen] = React.useState<any>(false);
  const [modalOpenEndedOpen, setModalOpenEndedOpen] = React.useState<any>(false);
  const [successModalMatchingQ, setSuccessModalMatchingQ] = React.useState<any>();
  const [mcqData,setMcqData]=useState<any>()
  const [pollData,setPollData]=useState<any>()
  const [open,setOpen]=useState<boolean>(false)
  const [openModal,setOpenModal]=useState<boolean>(false)
  const [timeSet, setTimeset] = useState<TimeSet>({ minutes: '00', seconds: '00' });
  const titleRef = React.useRef<any>(null);
  const [canvasObj,setCanvasObj] = useState<fabric.Canvas|null>(null)
  const [isCanvasFocused,setCanvasFocused] = useState<boolean>(false)
  const [multipleOption, setMultipleOption] = useState<boolean>(false);
  const [backgroundImage, setBackgroundImage] = React.useState<null>(null);
 
  const updateTime = (minutes:string, seconds:string) => {
    setTimeset({
      minutes,
      seconds
    })
  }

  useEffect(()=>{
    if(pollState?.questions?.[0].length===undefined){
      setMultipleOption(false)
    }
    if(pollState?.multiple!==undefined){
      setMultipleOption(pollState?.multiple)
    }
  },[pollState?.multiple,pollState])
  const handleOpenSuccessModal = () => {
    setSuccessModal(true);
  };

  const handleOpenSuccessChartModal =() => {
    setSuccessChartModal(true);
  }
  const handleCloseSuccessChartModal = () => {
    setSuccessChartModal(false);
  };
  const onClose = () => {
    setOpen(false)
  }

  const modalClose = () => {
    setOpenModal(false)
  };

  const handleOpenMcqModal = () => {
    setModalMcqOpen(true)
  }
  const handleOpenPollModal = () => {
    setModalPollOpen(true)
  }
  const handleOpenEndedQuestionModal = () => {
    setModalOpenEndedOpen(true)
  };

  const handleCloseMcqModal = () => {
    setModalMcqOpen(false);
  }
  
  const handleClosePollModal = () => {
    setModalPollOpen(false);
  }
 
  const handleCloseOpenEndedModal = () => {
    setModalOpenEndedOpen(false);
  }

  const handleCloseMcqSuccessModal = () => {
    setSuccessModal(false);
  };
  const handleTitleChange = (event: any) => {
    const newValue = event;
    setTitle(newValue);
  };
   
  const renderLayout = () => {
    if(loading){
      return <Loader loading />
    }
    if (action === ACTIONS.ADD_TEXT) {
      return (<>
        <AddText
          setAddTextState={setAddTextState}
          addTextState={addTextState}
        />
      </>
      );
    } else if (
      action === ACTIONS.KWL_CHART ||
      action === ACTIONS.T_CHART ||
      action === ACTIONS.SEED_DISCUSSION ||
      action === ACTIONS.WH_CHART
    ) {
      return (
        <Chart
          setChartState={setChartState}
          chartState={chartState}
          chartType={action}
        />
      );
    } else if (action === ACTIONS.MULTIMEDIA || action === "add_multimedia") {
      return <MultiMedia multiMediaState={multiMediaState} multiMediaData={multiMediaData} loading={loading}/>;
     } else if (action === ACTIONS.VENN_DIAGRAM) {
      return <VennDiagram vennState={vennState} setVennState={setVennState} />;
    } else if (action === ACTIONS.ADD_MCQ || action === "MCQ") {
      return <Mcq  openEndedState={openEndedState}
      handleOpenEndedChange={handleMcqChange}
      updateTime={updateTime} getOptions={(data) => setMcqData(data)} mcqState={mcqState} mcqSettingState={mcqSettingState} preview={preview} setPreview={setPreview} handleSwitchChange={handleMcqChange}  handleMcqClick={handleMcqClick} setBackgroundImage={setBackgroundImage}
      />;
    } else if (action === ACTIONS.ADD_POLL || action === "polling_question") {
      return <Poll  pollSettingState={pollSettingState}
      handlePollChange={handlePollChange}getOptions={(data)=>setPollData(data)} pollState={pollState} preview={preview} setPreview={setPreview}  updateTime={updateTime} setMultipleOption={setMultipleOption} multipleOption={multipleOption} setBackgroundImage={setBackgroundImage}/>;
    }
    else if (action === ACTIONS.ADD_WORD_CLOUD) {

      return (
        <CFWordCloud
          navigation={undefined}
          id=""
          setAddWordCloudState={setAddWordCloudState}
          addWordCloudState={addWordCloudState}
          enteriesCount={enteriesCount}
          setEnteriesCount={setEnteriesCount}
          preview={preview} 
          setPreview={setPreview}
          setIsSaveDisabled={setIsSaveDisabled}
          isSaveDisabled={isSaveDisabled}
        />
      );
    }  else if (action === ACTIONS.OPEN_ENDED_QUESTION ) {
      return <OpenEndedQuestion preview={preview}
      openendedState={openendedState}
      setopenendedState={openendedState}
      addOpenEndedTextState={addOpenEndedTextState}
      setPreview={setPreview}
      setAddOpenEndedTextState={setAddOpenEndedTextState}
      openEndedState={openEndedState}
      handleOpenEndedChange={handleOpenEndedChange}
      updateTime={updateTime}
      setBackgroundImage={setBackgroundImage}
        />;
    }else if (action === ACTIONS.ADD_MATCHING_Q) {
      return ( 
      <CfQuestionBank2
        openEndedState={openEndedState}
        handleOpenEndedChange={handleOpenEndedChange}
        navigation={undefined}
        id=""
        pairs={pairs}
        setPairs={setPairs}
        pairCount={pairCount}
        setPairCount={setPairCount}
        matchSettingState={matchSettingState}
        setMatchSettingState={setMatchSettingState}  
        preview={preview} 
        updateTime={updateTime}
        setPreview={setPreview}  
        setmatchquestionState={setmatchquestionState}
        matchquestionState={matchquestionState}
        handleMatchChange={handleMatchChange}
        matchSetState={matchSetState}
        setBackgroundImage={setBackgroundImage}
        />
      )
    }
    else {
      return <NewStage />;
    }
  };
  const shouldSkipModal = (isValid: boolean, buttonType: string): boolean => {
    return !isValid && buttonType === "save";
};
  const handleNext = (buttonType: string) => {
    setOpenCanvas(false)
    if(buttonType=="create" && stageId){
      handleActionChange("",0,false)
      return
    }
    let skipModalForMCQ = false;
    switch (action) {
        case ACTIONS.ADD_TEXT:
            handleAddText(buttonType);
            buttonType === "create" && setOpen(true)   
            break;
        case ACTIONS.KWL_CHART:
        case ACTIONS.T_CHART:
        case ACTIONS.WH_CHART:
        case ACTIONS.SEED_DISCUSSION:
            handleChart({
                description: chartState.description,
                question: chartState.question,
                title,
                type:selectedView,
                id: stageId
            }, action,buttonType);
            break;
        case ACTIONS.VENN_DIAGRAM:
            handleVennDiagram(buttonType);
            break;
        case ACTIONS.ADD_WORD_CLOUD:
                        handleWordCloud(buttonType);
            buttonType === "create" && setOpen(true)
            break;
        case ACTIONS.ADD_MCQ:
      case ACTIONS.MCQ:
        const isValidMCQ = handleMCQ(buttonType);
        if (!isValidMCQ && buttonType === "save") {
          skipModalForMCQ = true;
        }
        break;
        case ACTIONS.ADD_POLL:
        case ACTIONS.POLLING_Q:
          const isValidPoll = handlePoll(buttonType);
          if (!isValidPoll && buttonType === "save") {
            skipModalForMCQ = true;
          }
            break;
        case ACTIONS.OPEN_ENDED_QUESTION:
            handleOpenEndedQuestion(buttonType);
            break;
        case ACTIONS.MULTIMEDIA:
        case "add_multimedia":
            updateMultiMediaStage(editMultiMediaId, title,buttonType);
            buttonType === "create" && setOpen(true)
            break;
        case ACTIONS.ADD_MATCHING_Q:
          skipModalForMCQ = shouldSkipModal(handleMatchingQ(buttonType), buttonType);
            break;
        default:
            break;
    }
    if (buttonType === "save" && !skipModalForMCQ) {
      setOpenModal(true);
  }
};



const handleAddText = (handleAddText: string) => {
    const data = {
        title,
        description: [addTextState],
        type: selectedView,
        id: stageId
    };
    addTextStage(data, handleAddText);
};

const handleChart = (data: {}, actionType: string,buttonType:string) => {
    addChartStage(data, actionType,buttonType);
    buttonType === "create" && handleOpenSuccessChartModal();
};

const handleVennDiagram = (buttonType:string) => {
    const data = {
        title,
        question: vennState.question,
        description: [
            { topic: vennState.sectionA },
            { topic: vennState.sectionB },
            { topic: vennState.sectionC },
        ],
        type:selectedView,
        id: stageId
    };
    handleChart(data, ACTIONS.VENN_DIAGRAM,buttonType);
};

const handleWordCloud = (buttonType:string) => {
    const data = {
        title,
        description: addWordCloudState.description,
        backgroundImage: addWordCloudState.backgroundImage,
        type:selectedView,
        id: stageId
    };
    addWordCloudStage(data, ACTIONS.ADD_WORD_CLOUD,buttonType);
};

const handleMCQ = (buttonType:string) => {
    const allOptionsFilled = mcqData?.every((option: any) => option.text.trim() !== '');
    const correctOptionSelected = mcqData?.some((option: any) => option.isCorrect);

    if(mcqData?.length === 1) {
      handleOpenSuccessModal();
    } else if (allOptionsFilled && correctOptionSelected) {
        const data = {
            title,
            description: mcqData,
            point: mcqSettingState.checkedA,
            double_point: mcqSettingState.checkedB,
            image: backgroundImage,
            type:selectedView,
            parentStage:parentStage,
            id: stageId,
            countdown_timer: `${timeSet.minutes}: ${timeSet.seconds}`
        };
        addMcqStage(data,buttonType);
      if (buttonType === "create") {
        handleOpenSuccessModal();
      }
      return true;
    } else {
      return false;
    }
};

const handlePoll = (buttonType:string) => {
    const allOptionsFilled = pollData?.every((option: any) => option.text.trim() !== '');
    if(pollData?.length === 1) {
      handleOpenSuccessModal();
    } else if (pollData?.length > 0 && allOptionsFilled) {
        const data = {
            title,
            description: pollData,
            image: backgroundImage,
            type:selectedView,
            countdown_timer: `${timeSet.minutes}: ${timeSet.seconds}`,
            point: pollSettingState.checkedA,
            double_point: pollSettingState.checkedB,
            id:stageId,
            multiple:multipleOption
        };
        addPollStage(data,buttonType);
      if (buttonType === "create") {
        handleOpenSuccessModal();
      }
      return true;
    } else {
      return false;
    }
};

const handleOpenEndedQuestion = (buttonType:string) => {
  const data = {
    title,
    description: addOpenEndedTextState,
    image: backgroundImage,
    type:selectedView,
    parentStage:parentStage,
    countdown_timer: `${timeSet.minutes}: ${timeSet.seconds}`,
    id: stageId,
    point: openEndedState?.checkedA,
    double_point: openEndedState?.checkedB,
  };
    addOpenEndedStage(data,buttonType);
    buttonType === "create" && handleOpenSuccessModal();
};

const handleMatchingQ = (buttonType:string) => {
  const allPairsFilled = pairs?.every((option: any) => option.pair_answer.trim() !== '');
  const allPairQuestionFilled = pairs?.every((option:any)=>option.pair_question.trim() !== "");
  if(pairs?.length === 1) {
    setSuccessModalMatchingQ(true);
    return false;
  }else if(allPairsFilled && allPairQuestionFilled && pairs.length>0){
    const data = {
        title,
        pairs: pairs,
        type:selectedView,
        countdown_timer: `${timeSet.minutes}: ${timeSet.seconds}`,
        point: matchSetState.checkedA,
        double_point: matchSetState.checkedB,
        id: stageId,
        image: backgroundImage
    };
    addMatchingQStage(data,buttonType);
    if (buttonType === "create") {
      setSuccessModalMatchingQ(true);
    } return true;
  } else {
    return false;
  }
  };

  
  const handleTitle=(title:any)=> {
    switch (action) {
      case ACTIONS.ADD_MCQ:
          return title ? classes.contentTitlepadding : classes.contentTitleMCQ;
      case ACTIONS.ADD_POLL:
          return title ? classes.contentTitlepadding : classes.contentTitlePOLL;
      case ACTIONS.OPEN_ENDED_QUESTION:
          return title ? classes.contentTitlepadding : classes.contentTitleOPENENDED;
      default:
          return action===ACTIONS.ADD_MCQ || action==ACTIONS.ADD_POLL || action===ACTIONS.OPEN_ENDED_QUESTION ? classes.contentTitle : classes.contentWithoutBtnTitle;
  }
};

  const getActionType = () => {
    if (mcqData?.length === 1 && createdAction === ACTIONS.ADD_MCQ) {
      return ACTIONS.ADD_FAIL_MCQ;
    } else if (pollData?.length === 1 && createdAction === ACTIONS.ADD_POLL) {
      return ACTIONS.ADD_FAIL_POLL;
    } else if(pairs?.length===1 && createdAction===ACTIONS.ADD_MATCHING_Q){
      return ACTIONS.ADD_FAIL_MATCH;
    }
    return createdAction;
  }

  return (
    <Box sx={{ px: "25px" }}>
      <Box className={classes.nav}>
        <img src={backImage} onClick={redirectToDashboard} style={{cursor:"pointer"}}/>
        <Typography className={`${classes.title} arrow-title`}>{`Create ${selectedView===COURSES_PAGES.LESSON && "Lesson" || selectedView===COURSES_PAGES.ASSIGNMENT && "Assignment" || selectedView===COURSES_PAGES.QUIZ && "Quiz"}`}</Typography>
      </Box>
      <Box data-test-id="title-box" className={classes.contentTitleBox2}>
        <TextEditor
          value={title}
          className={handleTitle(title)}
          onBlur={handleTitleChange}
        />
      </Box>

      {renderLayout()}
      {action && (
        <Box className={classes.btnLayout}>
          <GenericButton
            data-test-id="submitbtnTestId"
            type="NORMAL"
            label="Save Stage"
            handleClick={() => handleNext("save")}
            customStyle={{ width: "150px" }}
            disabled={isSaveDisabled && action == ACTIONS.ADD_WORD_CLOUD}
          />
          <GenericButton
            data-test-id="submit-btn"
            type="COLORED"
            label="Create New Stage"
            handleClick={() => handleNext("create")}
            customStyle={{ width: "265px" }}
            disabled={isSaveDisabled && action == ACTIONS.ADD_WORD_CLOUD}
          />
        </Box>
      )}

      <ChartAddedSuccessModal
        successModal={successChartModal}
        handleCloseSuccessModal={handleCloseSuccessChartModal}
        action={createdAction}
        lessonName={lessonName}
      />

      <McqModal open={modalMcqOpen} onClose={handleCloseMcqModal} setfile={setMcqfile} data-test-id="mcq-modal"/>
      <McqModal open={modalPollOpen} onClose={handleClosePollModal}  setfile={setPollfile}/>
      <McqModal open={modalOpenEndedOpen} onClose={handleCloseOpenEndedModal} setfile={setOpenEndedQuestionfile} data-test-id="modal-close"/>
      
      <McqAddedSuccessModal 
         action={getActionType()}
         successModal={successModal}
         handleCloseSuccessModal={handleCloseMcqSuccessModal}
         lessonName={lessonName}
         selectedView={selectedView}
      />
       <AddMatchingQModal
       action={getActionType()}
       data-test-id="MatchingSuccessModal"
        open={successModalMatchingQ} 
        onClose={() => setSuccessModalMatchingQ(false)} 
        lessonName={lessonName}
      />
      <Modal open={open} onClose={onClose}  >
        <Box className={classes.modalStyles}>
        <img data-test-id="closehandleTestId" onClick={onClose} src={Cross} className={classes.crossImgStyle}/>
          <img src={TickRight} className={classes.tickImg}/>
          <Typography className={classes.stageBox}>New Stage Created</Typography>
          <Typography className={classes.title}>
            Your current slide changes are<br></br>automatically saved.
          </Typography>
        </Box>
        </Modal> 
      <Modal open={openModal} data-test-id ="openModal" onClose={modalClose} >
        <Box className={classes.modalStyles}>
          <img onClick={modalClose} src={Cross} className={classes.crossImgStyle} />
          <img src={TickRight} className={classes.tickImg} />
          <Box className={classes.suavedTextStyle}>
            <Typography className={classes.stageBox}>Changes Saved</Typography>
            <Typography className={classes.successBox}>Successfully!</Typography>
          </Box>
          <Typography className={classes.title}>
            All modifications made on the slide<br></br>have been saved.
          </Typography>
        </Box>
      </Modal> 
    </Box>
  );
};

export default EditLayout;
