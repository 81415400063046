import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextEditor from "../../../../../components/src/TextEditor";
const useStyles = makeStyles(() => ({
  editArea: {
    height: "740px !important",
    border: "1px solid rgba(149, 157, 165, 0.2)",
    borderRadius: "10px",
    marginBottom: "50px",
    padding: "24px 12px",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 400,
    overflow: "auto",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
  },
}));
const AddText = (props: any) => {
  const titleRef = React.useRef<any>(null);
  const { setAddTextState, addTextState } = props;
  const classes = useStyles();
  const handleInput = (event: any) => {
    setAddTextState(event);
  };
  return (
    <div style={{position:"relative",marginTop:"41px"}}>
      <TextEditor
        value={addTextState}
        className={classes.editArea}
        onBlur={handleInput}
      />
    </div>
  );
};

export default AddText;
