import React, { useEffect, useRef } from "react";
import JoditEditor from "jodit-pro-react";
import "./emoji.min.css"
import "./button-generator.min.css"
import "./jodit.fat.min.css"
import "./jodit.min.css"

interface Props {
  value: string,
  className: string,
  onBlur: (event: any) => void
}

const TextEditor = ({ value, className, onBlur }: Props) => {
  const titleRef = useRef<any>(null);
  const editor = useRef<any>(null);
 
  const handlePaste = async (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const pastedData = await navigator.clipboard.readText();
      if (editor.current) {
        editor.current.execCommand("insertHTML", false, pastedData); 
      }
    } catch (error) {
      console.error('Error reading clipboard data:', error);
    }
  };
 
  useEffect(() => {
    if (editor.current) {
      editor.current.events.on('paste', handlePaste); 
      editor.current.focus();
    }
    return () => {
      if (editor.current) {
        editor.current.events.off('paste', handlePaste);
      }
    };
  }, []);
 
  const handleButtonClick = (command: string) => {
    if (editor.current) {
      editor.current.execCommand(command); 
      editor.current.focus();
    }
  };

  return (
    <JoditEditor
      className={className}
      ref={titleRef}
      value={value}
      config={{
        highlightSignature: {
          schema: {
            '\\$\\{([^}]+)\\}': (jodit: { createInside: { element: (arg0: string, arg1: { style: { backgroundColor: string; }; }) => any; }; }, matched: any[]) => {
              let color = 'yellow'; 
      
              switch (matched[1]) {
                case 'formSubmittedDate':
                  color = 'red';
                  break;
      
                case 'formSessionURL':
                  color = '#0f0';
                  break;
              }
      
              const span = jodit.createInside.element('span', {
                style: {
                  backgroundColor: color
                }
              });
      
              return span;
            }
          }
        },
        placeholder: "Enter instructions or question here",
        license: "F719F-1DF81-BNJOO-L9HFY",
        className: className,
        uploader: {
          url: "/upload", 
          insertImageAsBase64URI: true, 
        },
        filebrowser: {
            ajax: {
                url: "/filebrowser", 
            },
            width: 500,
            height: 400,
        },
        toolbarAdaptive: false,
        buttons: [
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "eraser",
          "changeCase",
          "|",
          "ul",
          "ol",
          "|",
          "font",
          "fontsize",
          "paragraph",
          "lineHeight",
          "|",
          "superscript",
          "subscript",
          "classSpan",
          "|",
          {
            name: "image",
            tooltip: "Insert Image",
            exec: () => handleButtonClick("image")
          },
          {
            name: "video",
            tooltip: "Insert Video",
            exec: () => handleButtonClick("video")
          },
          "|",
          "spellcheck",
          "speechRecognize",
          "cut",
          "copy",
          "paste",
          "copyformat",
          "|",
          "hr",
          "table",
          "link",
          "symbols",
          "ai-commands",
          "ai-assistant",
          {
            name: "emoji",
            tooltip: "Insert Emoji",
            exec: () => handleButtonClick("emoji")
          },
          "|",
          "indent",
          "outdent",
          "align",
          "|",
          "brush",
          "buttonGenerator",
          "|",
          "undo",
          "redo",
          "about",
          "|",
        ],
      }}
      tabIndex={1}
      onBlur={onBlur}
      data-testId="stageDescriptionInput"
    />
  );
};

export default TextEditor;